import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {JwtService} from '../../../../shared/services/jwtservice';
import {DialogController} from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {config} from "../../../../shared/services/config";
import {KraPerformanceReviewService} from "../../../../shared/services/kraperformancereviewservice";
import {ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import {PerformanceMangemantService} from "../../../../shared/services/performancereviewservice";

@inject(DialogController, JwtService, Router, ValidationControllerFactory, DialogService, KraPerformanceReviewService, PerformanceMangemantService)
export class ViewPerformanceReview {
    pageSize = config.kra_PazeSize;
    topicsPageSize = config.kra_PazeSize;
    ViewID = 2;
    isOpen = null;
    Successmessages = false;
    kraCategories = [];
    markascompleted = false;
    markascompletachivementpopup = false;
    activeTab = 'tab1';
    KraSuccessMessage = false;
    finalsubmitvalidation = false;
    openedIndex = 0;
    kraTitleExit = false;


    activateTab(tab) {
        this.activeTab = tab;
    }

    constructor(controller, JwtService, router, controllerFactory, DialogService, KraPerformanceReviewService, PerformanceMangemantService) {
        this.controller = controller;
        this.jwtService = JwtService;
        this.empid = this.jwtService.getEmployeeId();
        this.dialogService = DialogService;
        this.KraPerformanceReview = KraPerformanceReviewService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.router = router;
        this.performancereviewservice = PerformanceMangemantService;
    }

    activate(params) {
        // Access the value from the URL query parameter
        this.employeePerformanceReviewID = params.EmployeePerformanceReviewID;
        this.assignedBulkData = params.bulkData;
    }

    attached() {
        this.KraPerformanceReview.GetEmployeePerformanceKRADetails(this.employeePerformanceReviewID)
            .then(data => {
                this.employeeAssignedKraDetails = data.CategoryList;
                this.employeeModel=data.employeeModel;
                this.krasamedata = data.CategoryList.EmployeePerformanceKRAList;
            })
    }


    deleteBulkAssignEmployee(bulkdata) {
        this.finalsubmitvalidation = false;
        const AssignEmployeeID = bulkdata.EmployeePerformanceReviewID;
        const deletebulkids = {
            EmployeeID: this.empid,
            EmployeePerformanceReviewID: AssignEmployeeID

        };
        this.performancereviewservice.DeleteBulkAssignData(deletebulkids).then(data => {
            this.DeletebulkResponce = data;
        });
    }


    performanceClicks(event) {
        event.preventDefault();
        const parentDiv = event.target.closest('.parent-div');

        // Close all open categories except the clicked one
        const allOpenCategories = document.querySelectorAll('.parent-div.performance-open');
        allOpenCategories.forEach(openCategory => {
            if (openCategory !== parentDiv) {
                openCategory.classList.remove('performance-open');
            }
        });

        // Toggle the clicked category
        if (parentDiv) {
            parentDiv.classList.toggle('performance-open');
        }
    }

    performanceClicks(event, index) {
        event.preventDefault();
        if (this.openedIndex === index) {
            this.openedIndex = -1; // Close the currently open array
        } else {
            this.openedIndex = index; // Open the clicked array
        }
    }


    /* markAsComplete(event, EmployeeGoalID, MarkAsCompletedIND, category) {
         const result = this.dialogService.open({
             viewModel: Markascompletemodel,
             model: '',
         }).whenClosed(response => {
             if (response.wasCancelled) {
                 this.markascompleted = false;
                 return;
             } else {
                 this.markascompleted = true;
                 this.markascompletachivementpopup = true;
                 const checkbox = document.querySelector(`input[name="achievement_${MarkAsCompletedIND == true}"]`);
                 if (checkbox) {
                     checkbox.checked = true;
                     const markascompletedata = {
                         EmployeeGoalID: EmployeeGoalID,
                         EmployeeID: this.empid
                     };
                     this.performaceService.MarkasCompletedKraCategories(markascompletedata)
                         .then(data => {
                             this.performaceService.KraCategories(this.empid, this.PeriodID, this.ReviewTypeID, this.ViewID)
                                 .then(data => {
                                     this.kraCategories = data.EmployeeCategoryList;
                                     const result = this.kraCategories?.find(item => item.EmployeeCategoryID === category.EmployeeCategoryID);
                                     const defaultCategory = result;
                                     this.toggleDisplay(defaultCategory);
                                 });
                         });
                 }
             }
         })
     }*/

    openFileDialog() {
        document.getElementById('attachments').click();
    }

    handleFileSelect(files) {
        const file = files[0];
        const maxSize = 2.5 * 1024 * 1024;
        if (file.size > maxSize) {
            this.fileMessage = 'File size should be less than 2.5 MB.';
            setTimeout(() => {
                this.fileMessage = null;
            }, 4000);
            this.removeFile();
            return;
        }
        else if (file.size <= maxSize) {
            this.files = file;
            let reader = new FileReader();
            reader.readAsDataURL(file);
            this.DocumentNames = file.name;
            reader.onload = () => {
                this.files = reader.result;
            };
        }
    }

    removeFile() {
        this.files = null;
        this.DocumentNames = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }

    toggleDisplay(category) {
        this.kraCategories.forEach(cat => {
            if (cat !== category) {
                cat.showDiv = false;
            }
        });
        category.showDiv = true;
    }


    openCategory(index) {
        this.isOpen = index;
    }

    BackPage() {
      //  const performancereviewURLBackPage = `/#/KRAPerformanceReview/employeeperformancelist`;
       // window.location.href = performancereviewURLBackPage;
    }
}
